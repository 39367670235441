import styled, { createGlobalStyle } from "styled-components"
import { H3, Span } from "../components/styles/TextStyles.styled"
import device from "../theme/devices"
import PagerComponent from "../components/Pager"

export const BlueBackground = createGlobalStyle`
  body {
    background: ${props => props.theme.secondaryColor};
    
  }
  
  main {
  color: white;
  }
`

export const FilterRow = styled.div`
  grid-row: 2;
  grid-column: 1 / 7;
  padding-top: 15px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: row;

  input::placeholder {
    color: white;
    opacity: 50%;
  }

  @media (${device.desktop}) {
    grid-column: 2/12;
    padding-top: 30px;
  }

  > button {
    margin-right: 42px;
    margin-top: 18px;
    svg {
      width: 13px;
      height: 13.5px;
    }
  }
`

export const FilterControls = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  > :nth-child(2) {
    align-self: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  span {
    margin-right: 50px;
    transition: border-bottom-color 250ms ease-out;
    cursor: pointer;
    border-bottom: 4px solid transparent;
    margin-bottom: 10px;
    padding-top: 10px;

    &.current,
    :hover {
      border-bottom-color: currentColor;
    }
  }

  #inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > .react-datepicker-wrapper,
    > div:not(.react-datepicker-wrapper) .react-datepicker__input-container {
      padding-top: 10px;
      padding-bottom: 10px;
      min-width: 205px;
      max-width: 250px;
      max-height: 150px;
      :first-child {
        margin-right: 11px !important;
      }
      > :first-child {
        height: 100%;
      }
      .input-wrapper {
        height: 100%;
        > :first-child {
          background: transparent;
          border-color: currentColor;
          color: currentColor;
          border-width: 1px;
          height: 100%;
          input {
            text-align: center;
          }
        }
      }
    }
  }

  .dropdown-wrapper {
    margin: 10px 0;
    flex-shrink: 0;
    overflow: hidden;
    max-width: 100%;
  }
`

export const SearchRow = styled.div`
  width: 100%;
  transition: all 250ms ease-in-out;
  max-height: ${props => (props.visible ? 200 : 0)}px;
  padding: ${props => (props.visible ? 15 : 0)}px 0;
  overflow: hidden;

  @media (${device.phone}) {
    max-width: 200px;
  }

  > :first-child {
    max-width: 600px;

    button {
      cursor: pointer;

      line {
        stroke: white;
      }
    }
  }
`

export const Loading = styled.div`
  grid-row: 3;
  grid-column: 1 / 7;
  text-align: center;
  padding-top: 10px;
  height: 50px;
  box-sizing: content-box;
  @media (${device.desktop}) {
    grid-column: 2 / 12;
    padding-top: 50px;
  }
`

export const EventContainer = styled.div`
  grid-row: 4;
  grid-column: 1 / 7;
  transition: opacity 240ms ease-out;

  @media (${device.desktop}) {
    grid-column: 2 / 12;
  }

  &:empty,
  &.faded {
    opacity: 0;
  }
`

export const Year = styled(H3)`
  &:not(:first-of-type) {
    margin-top: 95px;
  }
`

export const Events = styled.div``

export const EventRow = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 20px;
  color: rgba(255, 255, 255, 0.3);
  transition: color 250ms ease-out;
  border-bottom: 1px solid;
  padding-top: 21px;
  padding-bottom: 26px;

  &:hover {
    color: white;
    cursor: pointer;
  }
`

export const Date = styled.div`
  grid-column: 1 / 11;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  @media (${device.tablet}) {
    grid-column: 1 / 8;
    display: block;
  }
  @media (${device.desktop}) {
    grid-column: 1 / 3;
  }

  span {
    color: inherit;
    :first-child {
      font-weight: 600;
      font-size: 28px;
      line-height: 42px;
    }
    :not(:first-child) {
      display: block;
    }
  }
`

export const Title = styled.div`
  grid-row: 2;
  grid-column: 1 / 11;
  @media (${device.desktop}) {
    grid-row: 1;
    grid-column: 3 / 8;
  }

  h3 {
    margin: 0;
    color: white;
  }
  span {
    color: inherit;
    font-weight: 500;
  }
`

export const EventType = styled(Span)`
  grid-row: 3;
  grid-column: 1 / 6;
  @media (${device.tablet}) {
    grid-row: 1;
    grid-column: 8 / 10;
  }
  display: block;
  font-weight: 500;
  color: inherit;
  line-height: 42px;
`

export const Price = styled(Span)`
  grid-row: 3;
  grid-column: 6 / 11;
  text-align: right;
  @media (${device.tablet}) {
    grid-row: 1;
    grid-column: 10;
    text-align: inherit;
  }
  display: block;
  color: inherit;
  font-weight: 500;
  line-height: 42px;
`

export const NotFoundLabel = styled.span`
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  display: block;

  & + span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    color: rgba(255, 255, 255, 0.3);
  }
`

export const Pager = styled(PagerComponent)`
  margin-top: 50px;
`
