import React from "react"
import styled, { css } from "styled-components"
import Input from "../Input"
import { ReactComponent as Close } from "../../assets/ic-close.svg"

const StyledInput = styled(Input)`
  position: relative;
  overflow-y: hidden;

  ${props =>
    !props.hasValue &&
    css`
      && {
        color: transparent;
      }
    `}

  ::-webkit-calendar-picker-indicator {
    cursor: pointer;
    width: 100%;
    opacity: 0;
    position: absolute;
    height: 100%;
  }

  .datetime-reset-button, // FF - not working
  ::-webkit-clear-button,
  ::ms-clear,
  ::-webkit-calendar-picker-indicator,
  ::-webkit-inner-spin-button {
    display: none;
  }
`

const CloseButtonContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  padding-right: 4px;
  svg {
    line {
      stroke: currentColor;
    }
  }

  ${props =>
    !props.visible &&
    css`
      opacity: 0;
      width: 0;
      overflow: hidden;
      pointer-events: none;
    `}
`

const Placeholder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;

  ${props =>
    props.hidden &&
    css`
      display: none;
    `}
`

const SiteInput = React.forwardRef((props, ref) => {
  return (
    <StyledInput
      ref={ref}
      placeholder={props.placeHolder}
      pattern="\d{4}-\d{2}-\d{2}"
      {...props}
    />
  )
})

const DateInput = ({ locale, value, onChange, placeholder, ...props }) => {
  return (
    <div className="react-datepicker-wrapper" style={{ position: "relative" }}>
      <SiteInput
        hasValue={!!value}
        suffix={
          <CloseButtonContainer
            visible={!!value}
            onClick={() => onChange && onChange("")}
          >
            <Close />
          </CloseButtonContainer>
        }
        type="date"
        value={value}
        onChange={e => {
          const value = e?.target?.value
          onChange(value)
        }}
        {...props}
      />
      <Placeholder hidden={!!value}>{placeholder}</Placeholder>
    </div>
  )
}

export default DateInput
