import React, { useCallback, useContext, useEffect, useState } from "react"
import * as S from "./agenda.styled"
import dark from "../theme/dark"
import light from "../theme"
import { graphql, navigate } from "gatsby"
import GridLayout from "../layouts/GridLayout"
import LinkedSectionTitle from "../components/SectionTitles/LinkedSectionTitle"
import {
  AgendaTitle,
  ArticleTitle,
  H3,
  Span,
} from "../components/styles/TextStyles.styled"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { urlFor } from "../util/content-links"
import {
  IconButton,
  IconButtonNoBackground,
} from "../components/styles/Buttons.styled"
import { ReactComponent as Search } from "../assets/ic-search.svg"
import Dropdown from "../components/Dropdown"
import DateInput from "../components/DateInput"
import PageWrapperContext from "../context/PageWrapperContext"
import Input from "../components/Input"
import PageTransition from "gatsby-plugin-page-transitions"
import { ReactComponent as CloseIcon } from "../assets/ic-close.svg"
import { useQueryParam } from "gatsby-query-params"
import { useEvents } from "../hooks/useEvents"

const AgendaPage = ({
  pageContext: { title, locale },
  data: {
    labels,
    agenda: { seoMetaTags },
    eventTypes,
    countries,
    language,
  },
}) => {
  const [hasModal, setHasModal] = useState(false)
  useEffect(() => {
    if (typeof document !== "undefined")
      setTimeout(
        () =>
          setHasModal(
            document.body.className.includes("ReactModal__Body--open")
          ),
        250
      )
  })
  const [searchValues, setSearchValues] = useState({
    country: language.country.originalId,
    from: null,
    to: null,
    text: "",
  })

  const eventType = useQueryParam("type", null)

  const {
    setHeaderTheme,
    setTheme,
    setDecorated,
    setHeaderCollapsedTheme,
  } = useContext(PageWrapperContext)
  useEffect(() => {
    setHeaderTheme(hasModal ? light : dark)
    setTheme(dark)
    setHeaderCollapsedTheme(light)
    setDecorated(true)
  })

  const { events, loading, error } = useEvents(searchValues, eventType, locale)

  const [page, setPage] = useState(0)
  const [changingPage, setChangingPage] = useState(false)
  useEffect(() => setPage(0), [loading])

  const changePage = useCallback(i => {
    window.scrollTo({ top: 0 })
    setChangingPage(true)
    setTimeout(() => {
      setPage(i)
      setTimeout(() => setChangingPage(false), 250)
    }, 250)
  }, [])

  const types = eventTypes.edges
    .filter(e => e.node.locale === locale)
    .map(e => e.node)

  const [searching, setSearching] = React.useState(false)

  return (
    <PageTransition>
      <HelmetDatoCms seo={seoMetaTags} />
      <S.BlueBackground />
      <GridLayout>
        <LinkedSectionTitle title={title} titleAs={ArticleTitle} />
        <S.FilterRow>
          <IconButton
            onClick={() => setSearching(s => !s)}
            style={{ cursor: "pointer" }}
          >
            <Search />
          </IconButton>
          <S.FilterControls>
            <S.SearchRow visible={searching}>
              <Input
                placeholder={labels.agendaSearchPlaceholder}
                value={searchValues.text}
                onChange={e => {
                  const text = e.target.value
                  setSearchValues(s => ({ ...s, text }))
                }}
                suffix={
                  <IconButtonNoBackground
                    onClick={() => setSearchValues(s => ({ ...s, text: "" }))}
                  >
                    <CloseIcon />
                  </IconButtonNoBackground>
                }
              />
            </S.SearchRow>
            <div>
              {[
                ["all", labels.all],
                ...types.map(t => [t.originalId, t.name]),
              ].map(([key, label]) => (
                <H3
                  key={key}
                  as="span"
                  className={eventType === key ? "current" : null}
                  onClick={() => {
                    if (key !== eventType) {
                      if (key === "all")
                        navigate(`${urlFor("DatoCmsAgenda", locale)}`)
                      else
                        navigate(
                          `${urlFor("DatoCmsAgenda", locale)}?type=${key}`
                        )
                    }
                  }}
                >
                  {label}
                </H3>
              ))}
            </div>

            <div id="inputs">
              <DateInput
                altStyle
                placeholder={labels.from}
                locale={language.locale}
                value={searchValues.from}
                onChange={v =>
                  setSearchValues(s => ({
                    ...s,
                    from: v,
                  }))
                }
              />
              <DateInput
                altStyle
                placeholder={labels.to}
                locale={language.locale}
                value={searchValues.to}
                onChange={v =>
                  setSearchValues(s => ({
                    ...s,
                    to: v,
                  }))
                }
              />
            </div>

            <Dropdown
              className="country-selector"
              value={searchValues.country}
              onChange={e =>
                setSearchValues(s => ({ ...s, country: e.target.value }))
              }
            >
              {countries.edges.map(({ node: { name, originalId } }) => (
                <option key={name} value={originalId}>
                  {name}
                </option>
              ))}
            </Dropdown>
          </S.FilterControls>
        </S.FilterRow>
        <S.Loading>{(loading || changingPage) && labels.loading}</S.Loading>
        <S.EventContainer className={`${(loading || changingPage) && "faded"}`}>
          {!loading && !error && (!events || events.length === 0) && (
            <div>
              {labels.noEventsFound.split("\n").map(l => (
                <S.NotFoundLabel>{l}</S.NotFoundLabel>
              ))}
            </div>
          )}
          {!loading && error && labels.errorLoading}
          {!changingPage &&
            events?.[page]?.map((x, i) => (
              <React.Fragment key={i}>
                <S.Year index={i}>{x.year}</S.Year>
                <S.Events index={i}>
                  {x.events.map((e, j) => (
                    <S.EventRow
                      key={e.id}
                      onClick={() =>
                        navigate(urlFor("DatoCmsEvent", locale, e.slug), {
                          state: { modal: true },
                        })
                      }
                    >
                      <S.Date>
                        <span>
                          {trainingDate(locale, e.startDate, e.endDate)}
                        </span>
                        <br />
                        <Span>
                          {trainingTime(locale, e.startDate, e.endDate)}
                        </Span>
                      </S.Date>
                      <S.Title>
                        <AgendaTitle>{e.title}</AgendaTitle>
                        <Span>{e.seo.description}</Span>
                      </S.Title>
                      {e.eventSubtype && (
                        <S.EventType>{e.eventSubtype?.name}</S.EventType>
                      )}
                      {e.price && <S.Price>{e.price}</S.Price>}
                    </S.EventRow>
                  ))}
                </S.Events>
              </React.Fragment>
            ))}
        </S.EventContainer>
        {events && events.length > 1 && (
          <S.Pager
            forGridLayout
            pages={events.length}
            current={page}
            onPageClick={changePage}
          />
        )}
      </GridLayout>
    </PageTransition>
  )
}

const trainingDate = (locale, start, end) => {
  start = new Date(start)
  end = new Date(end)

  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
  }
  const parsedStart = start.toLocaleDateString(locale, options)
  const parsedEnd = end.toLocaleDateString(locale, options)

  if (parsedStart === parsedEnd) return parsedStart
  if (start.getMonth() === end.getMonth())
    return `${start.toLocaleDateString(locale, {
      ...options,
      month: undefined,
    })} - ${end.toLocaleDateString(locale, {
      ...options,
      weekday: undefined,
    })}`
  return `${parsedStart} - ${end.toLocaleDateString(locale, {
    ...options,
    weekday: undefined,
  })}`
}

const trainingTime = (locale, start, end) => {
  const options = {
    hour: "2-digit",
    minute: "2-digit",
  }
  return `${new Date(start).toLocaleTimeString(locale, options)} - ${new Date(
    end
  ).toLocaleTimeString(locale, options)}`
}

export default AgendaPage

export const query = graphql`
  query($locale: String!) {
    labels: datoCmsTranslation(locale: { eq: $locale }) {
      from
      to
      all
      trainings
      events
      loading
      errorLoading
      noEventsFound
      agendaSearchPlaceholder
    }
    agenda: datoCmsAgenda(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    eventTypes: allDatoCmsAgendaItemType {
      edges {
        node {
          originalId
          locale
          name
        }
      }
    }
    countries: allDatoCmsCountry(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          name
          originalId
        }
      }
    }
    language: datoCmsLanguage(locale: { eq: $locale }) {
      locale
      country {
        originalId
      }
    }
  }
`
